.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.todo-animation-card {
  width: 600px;
  margin: auto;
  text-align: center;
  background: #fff;
  border: 1px solid #44495c40;
  border-radius: 20px;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  padding: 5px;
}
.text-box-wrap {
  margin-top: 10px !important;
}
.to-do-animation-head {
  h1 {
    font-size: 15px;
    color: #c4187a;
    font-weight: 700;
  }
  p {
    font-size: 13px;
    color: #44495c;
  }
}

.cls-1,
.cls-2,
.cls-3,
.cls-4,
.cls-5,
.cls-6,
.cls-7 {
  stroke: #444a5e;
  stroke-miterlimit: 6;
}
.cls-1 {
  fill: #fff;
  &.active {
    fill: #a11c21;
  }
}
.cls-2 {
  fill: #fff;
  &.active {
    fill: #f47d20;
  }
}

.cls-3 {
  fill: #fff;
  &.active {
    fill: #4777b8;
  }
}

.cls-4 {
  fill: #fff;
  &.active {
    fill: #f03a21;
  }
}

.cls-5 {
  fill: #fff;
  &.active {
    fill: #71c313;
  }
}

.cls-6 {
  fill: #fff;
  &.active {
    fill: #b1cb4f;
  }
}
.cls-7 {
  fill: #fff;
  &.active {
    fill: #3399cc;
  }
}
.todo-animation {
  display: flex;
  align-items: center;
  justify-content: center;
  figure {
    flex: none;
    width: 125px;
    margin: 0;
    max-width: 22%;
  }
}
.todo-category {
  list-style: none;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  padding: 0;
  flex-wrap: wrap;
  
  li {
    font-size: 13px;
    font-weight: 700;
    display: flex;
    align-items: baseline;
    margin: 2px 10px;
    p {
      text-align: center;
      margin: 0;
    }
    span {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      margin-right: 5px;
    }
    input {
      width: 40px;
      height: 22px;
      font-size: 16px;
    }
    label {
      margin-right: 5px;
    }
    &.logistic-count {
      color: #e97850;
      span {
        background-color: #e97850;
      }
    }
    &.aesthetics-count {
      color: #dc4a36;
      span {
        background-color: #dc4a36;
      }
    }
    &.hospitality-count {
      color: #7fba3d;
      span {
        background-color: #7fba3d;
      }
    }
    &.experience-count {
      color: #3399cc;
      span {
        background-color: #3399cc;
      }
    }
  }
}

.stars-grp {
  position: relative;
  width: 100%;
  height: 100%;
  &.onemonth-garland{
    path {
      fill: #ebcb61;
    }
  }
  &.hospitality-garland {
    path {
      fill: #7fba3d;
    }
  }
  &.experience-garland {
    path {
      fill: #3399cc;
    }
  }
  &.logistics-garland {
    path {
      // fill: #e97850;
      fill: #efa333;
    }
  }
  &.aesthetics-garland {
    path {
      fill: #dc4a36;
    }
  }
}

$falling-time: 2500ms;
.falling-star {
  position: absolute;
  left: 10%;
  top: 10%;
  width: 20px;
  border-radius: 999px;
  animation: falling $falling-time 2 forwards;

  @for $i from 1 through 35 {
    &:nth-child(#{$i}) {
      $delay: random(3000) + 0ms;
      top: 0px;
      left: calc(#{$i * 20px - 20px});
      animation-delay: $delay;
    }
  }
}

@keyframes falling {
  0% {
    transform: translateY(0);
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(300px);
  }
}

// .hidden {
//   display: none;
// }
main {
  @media (max-width: 767px) {
    padding: 15px !important;
  }
}
header{
  @media (max-width: 767px) {
   overflow: hidden;
  }
}
#confetti-canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}
.rangoli-wrp {
  width: 54%;
}
